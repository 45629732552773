import React from "react";

import SEO from "../components/seo";
import { Box, Typography, Divider, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ContactForm } from "../forms/contact-form";
import { motion } from "framer-motion";

const useStyles = makeStyles((theme) => {
  return {
    section: {
      margin: theme.spacing(15, 0),
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(5, 0, 10, 0),
      },
    },
  };
});

const Contact = () => {
  const { section } = useStyles();
  return (
    <>
      <SEO title="Contact Me" />
      <motion.div
        key="contact"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 1 } }}
      >
        <Box component="section" className={section}>
          <Box>
            <Typography variant="h3">Contact Me</Typography>
            <Divider style={{ marginBottom: "30px" }} />
          </Box>
        </Box>
        <Box component="section" className={section}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10} lg={8}>
              <Typography variant="h5">Get in touch with me!</Typography>
              <Typography variant="body1" paragraph>
                Please complete the form below to get in touch with me.
              </Typography>
              <ContactForm />
            </Grid>
          </Grid>
        </Box>
      </motion.div>
    </>
  );
};

export default Contact;
